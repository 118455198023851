import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoMdLink } from "react-icons/io";
import { HiOutlineCalendar } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { IoAddOutline } from "react-icons/io5";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import { BiArrowBack } from "react-icons/bi";
import toast from "react-hot-toast";
import { baseApiURL } from "../baseUrl";
import Heading from "./Heading";
const Notice = () => {
  const router = useLocation();
  const [notice, setNotice] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState("");
  const [data, setData] = useState({
    productTitle: "",
    productDescription: "",
    imageUrl: "",
    price: 0,
    discount: 0,
    weight: 0,
    stockQuantity: 0,
    expiryDate: "",
  });

  const getProductsHandler = () => {
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${baseApiURL()}/product/getProducts`, {}, { headers })
      .then((response) => {
        if (response.data.success) {
          setNotice(response.data.products);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error("Failed to retrieve products");
        console.error("Error fetching products:", error);
      });
  };

  useEffect(() => {
    getProductsHandler();
  }, []);

  const addProductHandler = (e) => {
    e.preventDefault();
    toast.loading("Adding Product");
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${baseApiURL()}/product/addProduct`, data, { headers })
      .then((response) => {
        toast.dismiss();
        if (response.data.success) {
          toast.success(response.data.message);
          getProductsHandler();
          setOpen(false);
          setData({
            productTitle: "",
            productDescription: "",
            imageUrl: "",
            price: 0,
            discount: 0,
            weight: 0,
            stockQuantity: 0,
            expiryDate: "",
          });
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Failed to add product");
        console.error("Error adding product:", error);
      });
  };

  const deleteProductHandler = (id) => {
    toast.loading("Deleting Product");
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .delete(`${baseApiURL()}/product/deleteProduct/${id}`, { headers })
      .then((response) => {
        toast.dismiss();
        if (response.data.success) {
          toast.success(response.data.message);
          getProductsHandler();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Failed to delete product");
        console.error("Error deleting product:", error);
      });
  };

  const updateProductHandler = (e) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .put(`${baseApiURL()}/product/updateProduct/${id}`, data, { headers })
      .then((response) => {
        toast.dismiss();
        if (response.data.success) {
          toast.success(response.data.message);
          getProductsHandler();
          setOpen(false);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Failed to update product");
        console.error("Error updating product:", error.response.data.message); // Log the specific error message
      });
  };

  const setOpenEditSectionHandler = (product) => {
    setEdit(true);
    setOpen(true);
    setData({
      productTitle: product.productTitle,
      productDescription: product.productDescription,
      imageUrl: product.imageUrl,
      price: product.price,
      discount: product.discount,
      weight: product.weight || 0,
      stockQuantity: product.stockQuantity || 0,
      expiryDate: product.expiryDate || "",
    });
    setId(product._id);
  };

  const openHandler = () => {
    setOpen(!open);
    setEdit(false);
    setData({
      productTitle: "",
      productDescription: "",
      imageUrl: "",
      price: 0,
      discount: 0,
      weight: 0,
      stockQuantity: 0,
      expiryDate: "",
    });
  };

  return (
    <div className="w-[85%] mx-auto flex justify-center items-start flex-col my-10">
      <div className="relative flex justify-between items-center w-full">
        <Heading title="Products" />
        {(router.pathname === "/admin" || router.pathname === "/weekly") && (
          <button
            className="absolute right-2 flex justify-center items-center border-2 border-red-500 px-3 py-2 rounded text-red-500"
            onClick={openHandler}
          >
            {open ? (
              <>
                <BiArrowBack className="text-red-500" />
                <span className="ml-2">Close</span>
              </>
            ) : (
              <>
                <IoAddOutline className="text-red-500 text-xl" />
                <span className="ml-2">Add Product</span>
              </>
            )}
          </button>
        )}
      </div>
      {!open && (
        <div className="mt-8 w-full">
          {notice.length > 0 ? (
            notice.map((product) => (
              <div
                key={product._id}
                className="border-blue-500 border-2 w-full rounded-md shadow-sm py-4 px-6 mb-4 relative"
              >
                {(router.pathname === "/admin" ||
                  router.pathname === "/weekly") && (
                  <div className="absolute flex justify-center items-center right-4 bottom-3">
                    <span
                      className="text-2xl group-hover:text-blue-500 ml-2 cursor-pointer hover:text-red-500"
                      onClick={() => deleteProductHandler(product._id)}
                    >
                      <MdDeleteOutline />
                    </span>
                    <span
                      className="text-2xl group-hover:text-blue-500 ml-2 cursor-pointer hover:text-blue-500"
                      onClick={() => setOpenEditSectionHandler(product)}
                    >
                      <MdEdit />
                    </span>
                  </div>
                )}
                <p className="text-xl font-medium">{product.productTitle}</p>
                <p className="text-lg font-normal mt-1 break-all">
                  {product.productDescription}
                </p>
                <p className="text-sm absolute top-4 right-4 flex justify-center items-center">
                  <span className="text-base mr-1">
                    <HiOutlineCalendar />
                  </span>
                  {new Date(product.createdAt).toLocaleString()}
                </p>
              </div>
            ))
          ) : (
            <p>No products available</p>
          )}
        </div>
      )}
      {open && (
        <form className="mt-8 w-full">
          <div className="w-[40%] mt-2">
            <label htmlFor="productTitle">Product Title</label>
            <input
              type="text"
              id="productTitle"
              className="bg-blue-50 py-2 px-4 w-full mt-1"
              value={data.productTitle}
              onChange={(e) =>
                setData({ ...data, productTitle: e.target.value })
              }
            />
          </div>
          <div className="w-[40%] mt-4">
            <label htmlFor="productDescription">Product Description</label>
            <textarea
              id="productDescription"
              cols="30"
              rows="4"
              className="bg-blue-50 py-2 px-4 w-full mt-1 resize-none max-h-40"
              value={data.productDescription}
              onChange={(e) =>
                setData({ ...data, productDescription: e.target.value })
              }
            ></textarea>
          </div>
          <div className="w-[40%] mt-4">
            <label htmlFor="imageUrl">Image URL</label>
            <input
              type="text"
              id="imageUrl"
              className="bg-blue-50 py-2 px-4 w-full mt-1"
              value={data.imageUrl}
              onChange={(e) => setData({ ...data, imageUrl: e.target.value })}
            />
          </div>
          <div className="w-[40%] mt-4">
            <label htmlFor="price">Price</label>
            <input
              type="number"
              id="price"
              className="bg-blue-50 py-2 px-4 w-full mt-1"
              value={data.price}
              onChange={(e) =>
                setData({ ...data, price: parseFloat(e.target.value) || 0 })
              }
            />
          </div>
          <div className="w-[40%] mt-4">
            <label htmlFor="discount">Discount (%)</label>
            <input
              type="number"
              id="discount"
              className="bg-blue-50 py-2 px-4 w-full mt-1"
              value={data.discount}
              onChange={(e) =>
                setData({ ...data, discount: parseInt(e.target.value) || 0 })
              }
            />
          </div>
          <div className="w-[40%] mt-4">
            <label htmlFor="weight">Weight (optional)</label>
            <input
              type="number"
              id="weight"
              className="bg-blue-50 py-2 px-4 w-full mt-1"
              value={data.weight}
              onChange={(e) =>
                setData({ ...data, weight: parseFloat(e.target.value) || 0 })
              }
            />
          </div>
          <div className="w-[40%] mt-4">
            <label htmlFor="stockQuantity">Stock Quantity</label>
            <input
              type="number"
              id="stockQuantity"
              className="bg-blue-50 py-2 px-4 w-full mt-1"
              value={data.stockQuantity}
              onChange={(e) =>
                setData({
                  ...data,
                  stockQuantity: parseInt(e.target.value) || 0,
                })
              }
            />
          </div>
          <div className="w-[40%] mt-4">
            <label htmlFor="expiryDate">Expiry Date (optional)</label>
            <input
              type="date"
              id="expiryDate"
              className="bg-blue-50 py-2 px-4 w-full mt-1"
              value={data.expiryDate}
              onChange={(e) => setData({ ...data, expiryDate: e.target.value })}
            />
          </div>
          {edit ? (
            <button
              onClick={updateProductHandler}
              className="bg-blue-500 text-white mt-6 px-6 rounded text-lg py-2 hover:bg-blue-600"
            >
              Update Product
            </button>
          ) : (
            <button
              onClick={addProductHandler}
              className="bg-blue-500 text-white mt-6 px-6 rounded text-lg py-2 hover:bg-blue-600"
            >
              Add Product
            </button>
          )}
        </form>
      )}
    </div>
  );
};

export default Notice;
