import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Heading from "./Heading";
import toast from "react-hot-toast";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cart, totalItems, totalPrice } = location.state;

  const [address, setAddress] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("creditCard");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simulate order submission
    setTimeout(() => {
      setIsSubmitting(false);
      setOrderSuccess(true);
      toast.success("Order submitted successfully!");
      // Clear form fields
      setAddress("");
      setPaymentMethod("creditCard");
    }, 1500); // Simulating a delay for submission
  };

  return (
    <div className="w-[95%] mx-auto flex justify-center items-start flex-col my-10">
      <Heading title="Checkout" />
      <div className="mt-6 flex justify-between items-center w-full">
        <div>
          <p className="text-lg font-semibold">Total items: {totalItems}</p>
          <p className="text-lg font-semibold">
            Total price: ₹{totalPrice.toFixed(2)}
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="mt-8 w-full max-w-lg mx-auto">
        <div className="mb-6">
          <label className="block text-lg font-medium mb-2" htmlFor="address">
            Address
          </label>
          <textarea
            id="address"
            className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-md"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-lg font-medium mb-2"
            htmlFor="paymentMethod"
          >
            Payment Method
          </label>
          <select
            id="paymentMethod"
            className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-md"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            required
          >
            <option value="creditCard">Credit Card</option>
            <option value="debitCard">Debit Card</option>
            <option value="paypal">PayPal</option>
            <option value="netBanking">Net Banking</option>
          </select>
        </div>
        <button
          type="submit"
          className={`w-full bg-blue-500 text-white py-2 px-4 rounded-md shadow-md ${
            isSubmitting ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit Order"}
        </button>
      </form>

      {/* Success message */}
      {orderSuccess && (
        <div className="mt-4 w-full max-w-lg mx-auto">
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 shadow-md">
            <p className="font-bold">Order submitted successfully!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;
