// import React, { useState } from "react";
// import { useForm } from "react-hook-form";
// import { FiLogIn } from "react-icons/fi";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import toast, { Toaster } from "react-hot-toast";
// import { baseApiURL } from "../baseUrl";

// const Check = () => {
//   const navigate = useNavigate();
//   const [selected, setSelected] = useState("User");
//   const { register, handleSubmit } = useForm();

//   const onSubmit = (data) => {
//     if (data.loginid !== "" && data.password !== "") {
//       const headers = {
//         "Content-Type": "application/json",
//       };

//       axios
//         .post(`${baseApiURL()}/user/auth/login`, data, {
//           headers: headers,
//         })
//         .then((response) => {
//           navigate(`/${selected.toLowerCase()}`, {
//             state: { type: selected, loginid: response.data.loginid },
//           });
//         })
//         .catch((error) => {
//           toast.dismiss();
//           console.error(error);
//           toast.error(error.response.data.message);
//         });
//     } else {
//       // Handle empty fields error if necessary
//     }
//   };

//   return (
//     <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
//       <div className="relative sm:max-w-sm sm:mx-auto">
//         <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-400 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-lg"></div>
//         <div className="relative bg-white shadow-lg sm:rounded-lg sm:px-10 py-10">
//           <div className="mb-8">
//             <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
//               Customer Login
//             </h2>
//           </div>
//           <div className="flex justify-center mb-8">
//             <div className="relative rounded-lg border border-gray-300 bg-white divide-x divide-gray-300">
//               <button
//                 onClick={() => setSelected("User")}
//                 className={`${
//                   selected === "User"
//                     ? "text-white bg-blue-600"
//                     : "text-gray-700"
//                 } relative px-6 py-3 focus:outline-none`}
//               >
//                 User
//               </button>
//             </div>
//           </div>
//           <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
//             <div>
//               <label htmlFor="loginid" className="sr-only">
//                 {selected} Login ID
//               </label>
//               <input
//                 id="loginid"
//                 name="loginid"
//                 type="number"
//                 autoComplete="loginid"
//                 required
//                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
//                 placeholder={`${selected} Login ID`}
//                 {...register("loginid")}
//               />
//             </div>
//             <div>
//               <label htmlFor="password" className="sr-only">
//                 Password
//               </label>
//               <input
//                 id="password"
//                 name="password"
//                 type="password"
//                 autoComplete="current-password"
//                 required
//                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
//                 placeholder="Password"
//                 {...register("password")}
//               />
//             </div>
//             <div>
//               <button
//                 type="submit"
//                 className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
//               >
//                 <span className="absolute left-0 inset-y-0 flex items-center pl-3">
//                   <FiLogIn
//                     className="h-5 w-5 text-blue-500 group-hover:text-blue-400"
//                     aria-hidden="true"
//                   />
//                 </span>
//                 Login
//               </button>
//             </div>
//           </form>
//           <Toaster position="bottom-center" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Check;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FiLogIn } from "react-icons/fi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { baseApiURL } from "../baseUrl";

const Check = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const { register, handleSubmit } = useForm();

  const organizationTypes = [
    "NGO",
    "Aasharam",
    "Army",
    "Handicap",
  ];

  const onSubmit = (data) => {
    if (data.loginid !== "" && data.password !== "" && data.organizationType !== "") {
      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(`${baseApiURL()}/user/auth/login`, { ...data, organizationType: selected }, { headers })
        .then((response) => {
          navigate(`/${selected.toLowerCase()}`, { state: { type: selected, loginid: response.data.loginid } });
        })
        .catch((error) => {
          toast.dismiss();
          console.error(error);
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please fill in all the fields.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="relative sm:max-w-sm sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-blue-400 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-lg"></div>
        <div className="relative bg-white shadow-lg sm:rounded-lg sm:px-10 py-10">
          <div className="mb-8">
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Customer Login
            </h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="loginid" className="sr-only">
                Login ID
              </label>
              <input
                id="loginid"
                name="loginid"
                type="number"
                autoComplete="loginid"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Login ID"
                {...register("loginid")}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                {...register("password")}
              />
            </div>
            <div>
              <label htmlFor="organizationType" className="sr-only">
                Organization Type
              </label>
              <select
                id="organizationType"
                name="organizationType"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                value={selected}
                onChange={(e) => setSelected(e.target.value)}
              >
                <option value="">Select Organization Type</option>
                {organizationTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <FiLogIn
                    className="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                    aria-hidden="true"
                  />
                </span>
                Login
              </button>
            </div>
          </form>
          <Toaster position="bottom-center" />
        </div>
      </div>
    </div>
  );
};

export default Check;