// import React from "react";
// import { FiLogOut } from "react-icons/fi";
// import { useLocation, useNavigate } from "react-router-dom";
// import { RxDashboard } from "react-icons/rx";

// const Navbar = () => {
//   const router = useLocation();
//   const navigate = useNavigate();
//   return (
//     <div className="shadow-md px-2 py-2 flex justify-between items-center">
//       <p
//         className="font-semibold text-2xl flex justify-center items-center cursor-pointer"
//         onClick={() => navigate("/")}
//       >
//         <span className="mr-2">
//           <RxDashboard />
//         </span>{" "}
//          Dashboard
//       </p>
//       <button
//         className="flex justify-center items-center text-red-500 px-2 py-1 font-semibold rounded-sm"
//         onClick={() => navigate("/")}
//       >
//         Logout
//         <span className="ml-2">
//           <FiLogOut />
//         </span>
//       </button>
//     </div>
//   );
// };

// export default Navbar;

import React from "react";
import { FiLogOut } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import klemartLogo from "../images/logo.jpg";
const Navbar = () => {
  const router = useLocation();
  const navigate = useNavigate();
  return (
    <div className="shadow-md px-2 py-2 flex justify-between items-center">
      <img
        src={klemartLogo}
        alt="Klemart Logo"
        className="h-16 w-auto cursor-pointer"
        onClick={() => navigate("/")}
      />
      <button
        className="flex justify-center items-center text-red-500 px-2 py-1 font-semibold rounded-sm"
        onClick={() => navigate("/")}
      >
        Logout
        <span className="ml-2">
          <FiLogOut />
        </span>
      </button>
    </div>
  );
};

export default Navbar;
