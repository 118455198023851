import React, { useEffect } from "react";
import { useState } from "react";
import Heading from "./Heading";
import axios from "axios";
import { IoMdLink } from "react-icons/io";
import { HiOutlineCalendar } from "react-icons/hi";
import toast from "react-hot-toast";
import { baseApiURL } from "../baseUrl";

const ViewProducts = () => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    getProductsHandler();
  }, []);

  const getProductsHandler = () => {
    axios
      .post(`${baseApiURL()}/product/getProducts`)
      .then((response) => {
        if (response.data.success) {
          setProducts(response.data.products);
          initializeCart(response.data.products);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error("Failed to fetch products");
      });
  };

  const initializeCart = (products) => {
    const initialCart = products.map((product) => ({
      product,
      quantity: 0,
    }));
    setCart(initialCart);
  };

  const handleQuantityChange = (index, change) => {
    const updatedCart = [...cart];
    updatedCart[index].quantity += change;
    if (updatedCart[index].quantity < 0) {
      updatedCart[index].quantity = 0;
    }
    setCart(updatedCart);
  };

  const addToCart = (index) => {
    const updatedCart = [...cart];
    updatedCart[index].quantity++;
    setCart(updatedCart);
  };

  const removeFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart[index].quantity--;
    if (updatedCart[index].quantity < 0) {
      updatedCart[index].quantity = 0;
    }
    setCart(updatedCart);
  };

  const getTotalItems = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const getTotalPrice = () => {
    return cart.reduce(
      (total, item) => total + item.product.price * item.quantity,
      0
    );
  };

  const checkout = () => {
    // Implement checkout functionality
    console.log("Implement checkout functionality");
  };

  return (
    <div className="w-[95%] mx-auto flex justify-center items-start flex-col my-10">
      <Heading title="Products" />
      <div className="mt-6 flex justify-between items-center w-full">
        <div>
          <p className="text-lg font-semibold">
            Total items: {getTotalItems()}
          </p>
          <p className="text-lg font-semibold">
            Total price: ₹{getTotalPrice().toFixed(2)}
          </p>
        </div>
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-md"
          onClick={checkout}
        >
          Checkout
        </button>
      </div>
      <div className="mt-8 w-full grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
        {products.map((product, index) => (
          <div
            key={product._id}
            className="border-blue-500 border-2 rounded-md shadow-md p-4 flex flex-col justify-between"
          >
            <p className="text-xl font-medium mb-2">{product.productTitle}</p>
            <img
              src={product.imageUrl}
              alt={product.productTitle}
              className="w-full h-52 object-cover mb-2"
            />
            <p className="text-lg font-normal mb-2">
              {product.productDescription}
            </p>
            <div className="flex justify-between items-center">
              <p className="text-lg font-semibold">₹{product.price}</p>
              <div className="flex items-center space-x-2">
                <button
                  className="bg-blue-500 text-white py-1 px-2 rounded-md shadow-md"
                  onClick={() => removeFromCart(index)}
                >
                  -
                </button>
                <span className="font-semibold">{cart[index].quantity}</span>
                <button
                  className="bg-blue-500 text-white py-1 px-2 rounded-md shadow-md"
                  onClick={() => addToCart(index)}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewProducts;
