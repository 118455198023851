import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { Toaster } from "react-hot-toast";
import Notice from "../../components/Notice";
import ViewNotices from "../../components/ViewNotices";
import Admin from "./Admin";
const Home = () => {
  const router = useLocation();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("ViewNotices");
  useEffect(() => {
    if (router.state === null) {
      navigate("/");
    }
    setLoad(true);
  }, [navigate, router.state]);


  // return (
  //   <>
  //     {load && (
  //       <>
  //         <Navbar />
  //         <div className="w-[100%] mx-auto mt-8 flex justify-center items-start flex-col container">
  //           <ul className="flex justify-evenly items-center gap-10 w-[90%] mx-auto">
  //             <li
  //               className={`text-center rounded-sm px-4 py-2 w-1/5 cursor-pointer ease-linear duration-300 hover:ease-linear hover:duration-300 hover:transition-all transition-all ${
  //                 selectedMenu === "ViewNotices"
  //                   ? "border-b-2 pb-2 border-blue-500 bg-blue-100 rounded-sm"
  //                   : "bg-blue-500 text-white hover:bg-blue-600 border-b-2 border-blue-500"
  //               }`}
  //               onClick={() => setSelectedMenu("ViewNotices")}
  //             >
  //               Tasks
  //             </li>
  //             <li
  //               className={`text-center rounded-sm px-4 py-2 w-1/5 cursor-pointer ease-linear duration-300 hover:ease-linear hover:duration-300 hover:transition-all transition-all ${
  //                 selectedMenu === "Notice"
  //                   ? "border-b-2 pb-2 border-blue-500 bg-blue-100 rounded-sm"
  //                   : "bg-blue-500 text-white hover:bg-blue-600 border-b-2 border-blue-500"
  //               }`}
  //               onClick={() => setSelectedMenu("Notice")}
  //             >
  //               Add Tasks
  //             </li>
  //             <li
  //               className={`text-center rounded-sm px-4 py-2 w-1/5 cursor-pointer ease-linear duration-300 hover:ease-linear hover:duration-300 hover:transition-all transition-all ${
  //                 selectedMenu === "Admin"
  //                   ? "border-b-2 pb-2 border-blue-500 bg-blue-100 rounded-sm"
  //                   : "bg-blue-500 text-white hover:bg-blue-600 border-b-2 border-blue-500"
  //               }`}
  //               onClick={() => setSelectedMenu("Admin")}
  //             >
  //               Admins
  //             </li>
  //           </ul>
  //         </div>
  //         <>
  //           {selectedMenu === "Notice" && <Notice />}
  //           {selectedMenu === "ViewNotices" && <ViewNotices />}
  //           {selectedMenu === "Admin" && <Admin />}
  //         </>
  //       </>
  //     )}
  //     <Toaster position="bottom-center" />
  //   </>
  // );

  return (
    <>
      {load && (
        <>
          <Navbar />
          <div className="w-[100%] mx-auto mt-8 flex justify-center items-start flex-col container">
            <ul className="flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-10 w-[90%] mx-auto">
              <li
                className={`text-center rounded-sm px-4 py-2 w-full sm:w-auto cursor-pointer ease-linear duration-300 hover:ease-linear hover:duration-300 hover:transition-all transition-all ${
                  selectedMenu === "ViewNotices"
                    ? "border-b-2 pb-2 border-blue-500 bg-blue-100 rounded-sm"
                    : "bg-blue-500 text-white hover:bg-blue-600 border-b-2 border-blue-500"
                }`}
                onClick={() => setSelectedMenu("ViewNotices")}
              >
                Tasks
              </li>
              <li
                className={`text-center rounded-sm px-4 py-2 w-full sm:w-auto cursor-pointer ease-linear duration-300 hover:ease-linear hover:duration-300 hover:transition-all transition-all ${
                  selectedMenu === "Notice"
                    ? "border-b-2 pb-2 border-blue-500 bg-blue-100 rounded-sm"
                    : "bg-blue-500 text-white hover:bg-blue-600 border-b-2 border-blue-500"
                }`}
                onClick={() => setSelectedMenu("Notice")}
              >
                Add Tasks
              </li>
              <li
                className={`text-center rounded-sm px-4 py-2 w-full sm:w-auto cursor-pointer ease-linear duration-300 hover:ease-linear hover:duration-300 hover:transition-all transition-all ${
                  selectedMenu === "Admin"
                    ? "border-b-2 pb-2 border-blue-500 bg-blue-100 rounded-sm"
                    : "bg-blue-500 text-white hover:bg-blue-600 border-b-2 border-blue-500"
                }`}
                onClick={() => setSelectedMenu("Admin")}
              >
                Admins
              </li>
            </ul>
          </div>
          <>
            {selectedMenu === "Notice" && <Notice />}
            {selectedMenu === "ViewNotices" && <ViewNotices />}
            {selectedMenu === "Admin" && <Admin />}
          </>
        </>
      )}
      <Toaster position="bottom-center" />
    </>
  );
};

export default Home;
