import React, { useEffect, useState, useMemo } from "react";
import Heading from "./Heading";
import axios from "axios";
import toast from "react-hot-toast";
import { baseApiURL } from "../baseUrl";
import { useNavigate } from "react-router-dom";

const ViewProductsSorted = () => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [filter, setFilter] = useState("all");
  const navigate = useNavigate();

  useEffect(() => {
    getProductsHandler();
  }, []);

  const getProductsHandler = async () => {
    try {
      const response = await axios.post(`${baseApiURL()}/product/getProducts`);
      if (response.data.success) {
        const sortedProducts = sortProductsByExpiry(response.data.products);
        setProducts(sortedProducts);
        initializeCart(sortedProducts);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to fetch products");
    }
  };

  const sortProductsByExpiry = (products) => {
    const currentDate = new Date();
    return products
      .map((product) => categorizeProduct(product, currentDate))
      .sort((a, b) => a.daysToExpire - b.daysToExpire); // Sort by daysToExpire ascending
  };

  const categorizeProduct = (product, currentDate) => {
    const expiryDate = new Date(product.expiryDate);
    const daysToExpire = Math.ceil(
      (expiryDate - currentDate) / (1000 * 3600 * 24)
    );
    product.daysToExpire = daysToExpire;
    if (daysToExpire <= 0) {
      product.discount = 1;
      product.category = "expired";
    } else if (daysToExpire <= 100) {
      product.discount = 1 - daysToExpire / 100;
      product.category = "red";
    } else {
      product.discount = 0;
      product.category = "green";
    }
    product.borderColor = calculateBorderColor(daysToExpire);
    product.bannerColor = calculateBannerColor(daysToExpire);
    return product;
  };

  const calculateBorderColor = (daysToExpire) => {
    if (daysToExpire <= 0) return "#8B0000"; // Dark Red for expired
    if (daysToExpire > 100) return "#006400"; // Dark Green for more than 100 days

    const redValue = Math.min(
      255,
      Math.max(0, 255 - Math.round((daysToExpire / 100) * 255))
    );
    const greenValue = Math.min(
      255,
      Math.max(0, Math.round((daysToExpire / 100) * 255))
    );
    return `rgb(${redValue}, ${greenValue}, 0)`; // Transition from red to green
  };

  const calculateBannerColor = (daysToExpire) => {
    if (daysToExpire <= 0) return "#8B0000"; // Dark Red for expired
    if (daysToExpire <= 20) return `rgb(${255 - daysToExpire * 12.75}, 0, 0)`; // Dark red to light red
    if (daysToExpire <= 40)
      return `rgb(${255 - daysToExpire * 6.375}, ${
        daysToExpire * 3.2 - 64
      }, 0)`; // Light red to dark orange
    if (daysToExpire <= 60)
      return `rgb(${255 - daysToExpire * 3.1875}, ${
        daysToExpire * 3.1875 - 64
      }, 0)`; // Dark orange to light orange
    if (daysToExpire <= 80)
      return `rgb(${255 - daysToExpire * 1.59375}, ${
        daysToExpire * 2.55 - 128
      }, ${daysToExpire * 1.59375})`; // Light orange to light blue
    if (daysToExpire <= 100)
      return `rgb(${255 - daysToExpire}, ${daysToExpire * 1.55}, ${
        daysToExpire * 2.55
      })`; // Light blue to light green
    return "#006400"; // Dark Green for more than 100 days
  };

  const initializeCart = (products) => {
    const initialCart = products.map((product) => ({
      product,
      quantity: 0,
    }));
    setCart(initialCart);
  };

  const handleQuantityChange = (index, change) => {
    setCart((prevCart) => {
      const updatedCart = [...prevCart];
      updatedCart[index].quantity = Math.max(
        0,
        updatedCart[index].quantity + change
      );
      return updatedCart;
    });
  };

  const addToCart = (index) => {
    handleQuantityChange(index, 1);
  };

  const removeFromCart = (index) => {
    handleQuantityChange(index, -1);
  };

  const getTotalItems = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const getTotalPrice = () => {
    return cart.reduce(
      (total, item) =>
        total +
        item.product.price * item.quantity * (1 - item.product.discount),
      0
    );
  };

  const discount = () => {
    navigate("/check");
  };

  const checkout = () => {
    navigate("/checkout", {
      state: {
        cart,
        totalItems: getTotalItems(),
        totalPrice: getTotalPrice(),
      },
    });
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredProducts = useMemo(() => {
    return products.filter(
      (product) => filter === "all" || product.category === filter
    );
  }, [products, filter]);

  return (
    <div className="w-[95%] mx-auto flex justify-center items-start flex-col my-10">
      <Heading title="Products Sorted by Expiry" />
      <div className="mt-6 flex justify-between items-center w-full">
        <div>
          <p className="text-lg font-semibold">
            Total items: {getTotalItems()}
          </p>
          <p className="text-lg font-semibold">
            Total price: ₹{getTotalPrice().toFixed(2)}
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <select
            className="bg-white border border-gray-300 py-2 px-4 rounded-md shadow-md"
            value={filter}
            onChange={handleFilterChange}
          >
            <option value="all">All</option>
            <option value="red">Red</option>
            <option value="green">Green</option>
            <option value="expired">Expired</option>
          </select>

          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-md shadow-md"
            onClick={checkout}
          >
            Checkout
          </button>
        </div>
      </div>
      <div className="mt-8 w-full grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
        {filteredProducts.map((product, index) => (
          <div
            key={product._id}
            style={{ borderColor: product.borderColor }}
            className="border-2 rounded-md shadow-md p-4 flex flex-col justify-between"
          >
            <div
              style={{ backgroundColor: product.bannerColor }}
              className="text-white py-1 px-2 rounded-md text-center mb-2"
            >
              {product.daysToExpire <= 0
                ? "Expired"
                : `${product.daysToExpire} days to expire`}
            </div>
            <p className="text-xl font-medium mb-2">{product.productTitle}</p>
            <img
              src={product.imageUrl}
              alt={product.productTitle}
              className="w-full h-52 object-cover mb-2 rounded-md"
            />
            <p className="text-md font-normal mb-2">
              {product.productDescription}
            </p>
            <p className="text-md font-normal mb-2">
              Expiry Date: {new Date(product.expiryDate).toLocaleDateString()}
            </p>
            <div className="flex justify-between items-center">
              <div>
                <p
                  className={`text-lg font-semibold ${
                    product.discount > 0 ? "line-through" : ""
                  }`}
                >
                  ₹{product.price}
                </p>
                {product.discount > 0 && (
                  <p className="text-lg font-semibold text-red-500">
                    ₹{(product.price * (1 - product.discount)).toFixed(2)} (
                    {(product.discount * 100).toFixed(0)}% off)
                  </p>
                )}
              </div>
              <div className="flex items-center space-x-2">
                <button
                  className="bg-blue-500 text-white py-1 px-2 rounded-md shadow-md"
                  onClick={() => removeFromCart(index)}
                >
                  -
                </button>
                <span className="font-semibold">{cart[index].quantity}</span>
                <button
                  className="bg-blue-500 text-white py-1 px-2 rounded-md shadow-md"
                  onClick={() => addToCart(index)}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewProductsSorted;
