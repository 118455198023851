import React from "react";
import ViewProductsPublic from "./Screens/Public/viewProducts";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import mystore from "./redux/store";
import AdminHome from "./Screens/Admin/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/Login";
import Check from "./components/Check";
import Checkout from "./components/Checkout";
import ViewProductsSorted from "./components/ViewProductsSorted";
const App = () => {
  return (
    <>
      <Provider store={mystore}>
        <Router>
          <Routes>
            <Route path="/" element={<ViewProductsPublic />} />
            <Route path="/adminLogin" element={<Login />} />
            <Route path="/admin" element={<AdminHome />} />
            <Route path="/check" element={<Check />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/army" element={<ViewProductsSorted />} />
            <Route path="/ngo" element={<ViewProductsSorted />} />
            <Route path="/aasharam" element={<ViewProductsSorted />} />
            <Route path="/handicap" element={<ViewProductsSorted />} />
          </Routes>
        </Router>
        <ToastContainer />
      </Provider>
    </>
  );
};

export default App;
